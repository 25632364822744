<template>
  <teleport :to="teleportTo" v-if="isReady">
    <div class="flex py-1 lg:py-2 px-1 sm:px-2 justify-between gap-1">
      <div class="flex items-center gap-0 lg:gap-2">
        <template v-if="selection.count.value">
          <core-selection-toolbar-button :icon="COMMON_ICONS.close" @click="selection.clear()" icon-color="text-heroblue">
            {{selection.count.value}} Selected
          </core-selection-toolbar-button>
          <u-divider orientation="vertical" class="h-full" />
        </template>
      </div>
      <div class="overflow-x-auto">
        <slot />
      </div>
    </div>
  </teleport>
</template>

<script setup>

  const props = defineProps({
    selectionComposable: Object,
    teleportToId: String
  });

  const selection = props.selectionComposable || useSelection();
  const teleportTo = `#${props.teleportToId || 'selection-toolbar'}`;

  const isReady = ref(false);
  onMounted(async () => {
    await nextTick();
    isReady.value = true;
  });
</script>
